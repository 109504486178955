/* Fonts goes here */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #1275bc;
  --secondary-color: #edff57;
  --tertiary-color: #0e153a;
  --body-font-color: #515151;
  --main-font: "Raleway", sans-serif;
  --body-font: "Roboto", sans-serif;
  --overlay: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

html {
  font-family: var(--body-font);
  font-size: 10px;
  color: var(--body-font-color);
  scroll-behavior: smooth;
}

section {
  padding: 3.9rem 0;
}

select {
  margin-top: 20px;
}

img {
  width: 100%;
  max-width: 100%;
}

a {
  text-decoration: none !important;
}

p {
  font-size: 1.6rem;
  font-family: var(--body-font);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--main-font);
}

/* 404 Error */

#title {
  text-align: center;
  font-size: 40px;
  margin-top: 40px;
  margin-bottom: -40px;
  position: relative;
  color: #fff;
}

.circles:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100px;
  background: #fff;
  position: absolute;
  top: -50px;
  left: 0;
  transform: skewY(-4deg);
  -webkit-transform: skewY(-4deg);
}

.circles {
  background: #fff;
  text-align: center;
  position: relative;
  margin-top: -60px;
  box-shadow: inset -1px -4px 4px rgba(0, 0, 0, 0.2);
}

.circles p {
  font-size: 240px;
  color: #fff;
  padding-top: 60px;
  position: relative;
  z-index: 9;
  line-height: 100%;
}

.circles p small {
  font-size: 40px;
  line-height: 100%;
  vertical-align: top;
}

.circles .circle.small {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: #48a9e6;
  position: absolute;
  z-index: 1;
  top: 80px;
  left: 50%;
  animation: 7s smallmove infinite cubic-bezier(1, 0.22, 0.71, 0.98);
  -webkit-animation: 7s smallmove infinite cubic-bezier(1, 0.22, 0.71, 0.98);
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}

.circles .circle.med {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #48a9e6;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 10%;
  animation: 7s medmove infinite cubic-bezier(0.32, 0.04, 0.15, 0.75);
  -webkit-animation: 7s medmove infinite cubic-bezier(0.32, 0.04, 0.15, 0.75);
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

.circles .circle.big {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #48a9e6;
  position: absolute;
  z-index: 1;
  top: 200px;
  right: 0;
  animation: 8s bigmove infinite;
  -webkit-animation: 8s bigmove infinite;
  animation-delay: 3s;
  -webkit-animation-delay: 1s;
}

@-webkit-keyframes smallmove {
  0% {
    top: 10px;
    left: 45%;
    opacity: 1;
  }
  25% {
    top: 300px;
    left: 40%;
    opacity: 0.7;
  }
  50% {
    top: 240px;
    left: 55%;
    opacity: 0.4;
  }
  75% {
    top: 100px;
    left: 40%;
    opacity: 0.6;
  }
  100% {
    top: 10px;
    left: 45%;
    opacity: 1;
  }
}
@keyframes smallmove {
  0% {
    top: 10px;
    left: 45%;
    opacity: 1;
  }
  25% {
    top: 300px;
    left: 40%;
    opacity: 0.7;
  }
  50% {
    top: 240px;
    left: 55%;
    opacity: 0.4;
  }
  75% {
    top: 100px;
    left: 40%;
    opacity: 0.6;
  }
  100% {
    top: 10px;
    left: 45%;
    opacity: 1;
  }
}

@-webkit-keyframes medmove {
  0% {
    top: 0px;
    left: 20%;
    opacity: 1;
  }
  25% {
    top: 300px;
    left: 80%;
    opacity: 0.7;
  }
  50% {
    top: 240px;
    left: 55%;
    opacity: 0.4;
  }
  75% {
    top: 100px;
    left: 40%;
    opacity: 0.6;
  }
  100% {
    top: 0px;
    left: 20%;
    opacity: 1;
  }
}

@keyframes medmove {
  0% {
    top: 0px;
    left: 20%;
    opacity: 1;
  }
  25% {
    top: 300px;
    left: 80%;
    opacity: 0.7;
  }
  50% {
    top: 240px;
    left: 55%;
    opacity: 0.4;
  }
  75% {
    top: 100px;
    left: 40%;
    opacity: 0.6;
  }
  100% {
    top: 0px;
    left: 20%;
    opacity: 1;
  }
}

@-webkit-keyframes bigmove {
  0% {
    top: 0px;
    right: 4%;
    opacity: 0.5;
  }
  25% {
    top: 100px;
    right: 40%;
    opacity: 0.4;
  }
  50% {
    top: 240px;
    right: 45%;
    opacity: 0.8;
  }
  75% {
    top: 100px;
    right: 35%;
    opacity: 0.6;
  }
  100% {
    top: 0px;
    right: 4%;
    opacity: 0.5;
  }
}
@keyframes bigmove {
  0% {
    top: 0px;
    right: 4%;
    opacity: 0.5;
  }
  25% {
    top: 100px;
    right: 40%;
    opacity: 0.4;
  }
  50% {
    top: 240px;
    right: 45%;
    opacity: 0.8;
  }
  75% {
    top: 100px;
    right: 35%;
    opacity: 0.6;
  }
  100% {
    top: 0px;
    right: 4%;
    opacity: 0.5;
  }
}

/* Spinner */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Header */

header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.nav {
  height: 7.2rem;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.logo {
  width: 200px;
  padding: 10px;
}

.menu-toggle {
  color: #fff;
  font-size: 2.2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2.5rem;
  cursor: pointer;
  z-index: 1500;
}

.menu-toggle .fa-bars,
.fa-times {
  color: var(--primary-color);
}

.nav-list {
  list-style: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  height: 100vh;
  background-color: var(--secondary-color);
  padding: 4.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 1250;
  transform: translateX(-100%);
  transition: transform 0.5s;
}

.open {
  transform: translateX(0);
}

.nav::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  /* background-color: rgba(18, 117, 188, 0.8); */
  z-index: 1000;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.5s;
}

/* .open .menu-toggle .fa-times {
  display: block;
}

.open .menu-toggle .fa-bars {
  display: none;
} */

/* .open .nav-list {
  transform: translateX(0);
}

.open .nav::before {
  opacity: 1;
  transform: scale(1);
} */

.nav-item {
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
}

.nav-link {
  display: block;
  color: var(--tertiary-color);
  text-transform: uppercase;
  font-size: 1.6rem;
  letter-spacing: 2px;
  transition: color 0.5s;
}

.nav-link:hover {
  color: var(--primary-color);
}

/* Hero */

.hero {
  background: url("./img/pic2.jpg") center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
}

.hero h1 {
  font-size: 7rem;
  opacity: 0;
  animation: fadeUp 0.5s forwards;
}

.btn {
  display: inline-block !important;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.cta-btn {
  margin-top: 2rem;
  font-size: 1.1rem;
  padding: 0.9rem 1.8rem;
  color: var(--primary-color);
  border-radius: 1rem;
  border: 1px var(--primary-color) solid;
  transition: background-color 0.5s;
}

.cta-btn:hover,
.cta-btn:focus {
  color: #fff;
  background-color: var(--primary-color);
}

.global-headline {
  text-align: center;
  margin-top: 3.9rem;
}

.sub-headline {
  letter-spacing: 0.7rem;
  font-size: 5rem;
  font-family: var(--main-font);
}

/* Home About */

.home-about p {
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 2.4rem;
}

.home-about-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-body {
  font-size: 1.5rem;
  color: var(--primary-color);
  position: relative;
  transition: color 0.5s;
  margin-top: 2rem;
}

.btn-body::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
  transition: background-color 0.5s;
}

.btn-body:hover,
.btn-body:focus {
  color: var(--tertiary-color);
}

.btn-body:hover::before,
.btn-body:focus::before {
  background-color: var(--secondary-color);
}

/* Home Services */

.home-services {
  background: #fff;
  padding-bottom: 20px;
}

.home-services p {
  margin-top: 10px;
  padding: 20px;
}

.home-services h3 {
  font-size: 3rem;
}

.home-services .col-md-3 {
  margin: 3rem;
}

.home-services .col-md-3:hover {
  background: var(--primary-color);
  color: #fff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  transition: 0.5s;
}

.home-services img {
  height: 150px !important;
  width: auto;
  margin: auto;
  padding: 1.5rem !important;
}

/* Home Contact */

.home-contact .contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Home Difference */

.home-difference {
  text-align: center;
}

.difference-content {
  margin-top: 6rem;
}

.difference-content h4 {
  font-family: var(--main-font);
  font-size: 3rem;
  margin-top: 3rem;
}

.difference-content img {
  width: 13rem;
}

/* About Page */

#team .card {
  margin-bottom: 2rem;
  padding: 2rem;
}

/* Contact Page */

.contact-content {
  margin-top: 5rem;
}

#post-list {
  margin-top: 4rem;
}

/* Projects Page */

.projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 30px 0 auto;
  gap: 30px;
}

.project {
  border: 1px solid var(--primary-color);
  border-radius: 3px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}

.projectinfo {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(12, 1fr);
}

.projectinfo > h2 {
  grid-column: span 12;
  text-align: center;
  font-size: 2.5rem;
  padding-top: 20px;
  font-weight: 700;
}

.projectinfo img {
  grid-column: span 8;
}

.projectinfo .info {
  grid-column: span 4;
}
/* Footer */

footer {
  margin-top: 4rem;
  padding: 7.9rem;
  background-color: #121212;
  color: #fff;
  text-align: center;
  position: relative;
}

.back-to-top {
  width: 7rem;
  height: 7rem;
  background-color: #121212;
  position: absolute;
  top: -2rem;
  left: 50%;
  border-radius: 50%;
  transform: translateX(-50%);
}

.back-to-top a {
  text-decoration: none;
}

.back-to-top i {
  display: block;
  color: #fff;
  font-size: 2rem;
  padding: 2rem;
}

.footer-content {
  overflow: hidden;
}

.footer-content h4 {
  font-size: 1.9rem;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 3px;
  margin-bottom: 3rem;
}

.social-icons {
  list-style: none;
  margin-bottom: 5.4rem;
  display: flex;
  justify-content: center;
}

.social-icons i {
  font-size: 2rem;
  padding: 0.8rem 2rem;
  opacity: 0.5;
  color: #fff;
  transition: color 0.5s;
}

.social-icons i:hover,
.social-icons i:focus {
  color: var(--primary-color);
}

.newsletter-form {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.newsletter-input {
  width: 100%;
  max-width: 25rem;
  padding: 1rem;
  border-radius: 0.5rem;
  outline: none;
}

.newsletter-btn {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1.6rem;
  padding: 1px 0.6rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10.5rem;
}

/* Media Queries */

@media screen and (max-width: 320px) {
  .newsletter-btn {
    margin-left: 5rem;
  }
}

@media screen and (max-width: 360px) {
  .newsletter-btn {
    margin-left: 6.5rem;
  }
}

@media screen and (max-width: 411px) {
  .newsletter-btn {
    margin-left: 9rem;
  }
}

@media screen and (min-width: 900px) {
  section {
    padding: 7.9rem;
  }

  .menu-toggle {
    display: none;
  }

  .nav {
    justify-content: space-between;
  }

  .nav-list {
    position: initial;
    width: initial;
    height: initial;
    background-color: transparent;
    padding: 0;
    justify-content: initial;
    flex-direction: row;
    transform: initial;
    transition: initial;
  }

  .nav-item {
    margin: 0 2.4rem;
    border: none;
  }

  .nav-item:last-child {
    margin-right: 0;
  }

  .nav-link {
    padding: 0;
    font-size: 1.3rem;
  }

  #hero h1 {
    font-size: 10rem;
    font-weight: bold;
  }

  .home-services .col-md-3 {
    margin: 4rem 0;
  }
}

@keyframes fadeUp {
  0% {
    transform: translateY(6rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
